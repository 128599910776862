@font-face {
  font-family: "Athiti-Medium";
  src: local("Athiti"),
    url(../src/assets/fonts/Athiti/Athiti-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Athiti-SemiBold";
  src: local("Athiti"),
    url(../src/assets/fonts/Athiti/Athiti-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Athiti-Bold";
  src: local("Athiti"),
    url(../src/assets/fonts/Athiti/Athiti-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans"),
    url(../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans-Italic";
  src: local("OpenSans"),
    url(../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Italic.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: local("OpenSans"),
    url(../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans"),
    url(../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans-Semibold";
  src: local("OpenSans"),
    url(../src/assets/fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf)
      format("truetype");
}

.App {
  text-align: left;
  font-family: "OpenSans-Regular", sans-serif;
  overflow: hidden;
}

.content {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}
